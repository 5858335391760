/* Modal container styling */
.modal {
  position: fixed;
  top: 20%;
  margin: 20px 0;
  right: 0;
  width: 200px;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  transform: translateX(100%);
}

/* Show the modal */
.modal.display-block {
  transform: translateX(0);
}

/* Hide the modal */
.modal.display-none {
  transform: translateX(100%);
}

/* Main content area of the modal */
.modal-main {
  padding: 10px;
  position: relative;
  overflow-y: visible;
}

/* Close button styling */
.close-btn {
  position: absolute;
  top: 10px;
  right: 20px;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close-btn:hover,
.close-btn:focus {
  color: black;
  text-decoration: none;
}

/* Button to sign up and unlock discount code */
.popup-btn {
  background-color: #6aade5;
  color: white;
  border: none;

  text-align: center;
  font-size: 16px;
  cursor: pointer;

}

.popup-btn:hover {
  background-color: #2a7cbf;
}

/* Centered header in the popup */
.popup-header {
  text-align: center;

}

.discount-text {
  margin: 0px;
  padding-left: 0px;
  font-size: 24px;
  color: #272401;

}

/* Discount text styling */
.discount-text h1 {

  font-size: 24px;
  color: #272401;
}

.discount-text p {
  padding-left: 0;
  padding-right: 0;
  font-size: 16px;
  color: #272401;
  text-align: center;

}

