.contact-container img {
    max-width: 60%;
    padding: 0 80px;
    height: auto;
    display: block;
    margin: 0 auto;
}

.sub-contact img {
    max-width: 15%;
    padding: 0 80px;
    height: auto;
    display: block;
    margin: 0 auto;
}

.sub-contact img {
    height: 100px;
    width: auto;
  }

.contact-container h1 {
    font-family: 'Montserrat';
    font-size: 50px;
    text-transform: uppercase;
    color: #45494c;
    font-weight: 400;
    margin: 20px;
}

.sub-contact h1 {
    font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
    font-size: 30px;
    margin: 30px 0;
    color: #5890e4; /* Primary color */
    text-align: center;
    border-bottom: 1px solid;
    border-top: 1px solid;
    padding: 20px;
}

form {
    max-width: 600px;
    margin: 20px auto;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

p {
    font-size: 18px;
    color: #333;
    margin: 0 0 20px 0;
}

label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    color: #333;
}

input, textarea, select {
    width: calc(100% - 22px);
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

input:focus, textarea:focus, select:focus {
    border-color: #9bbae7; /* Primary color focus */
    outline: none;
    box-shadow: 0 0 5px rgba(155, 186, 231, 0.5);
}

button {
    padding: 10px 20px;
    background-color: #9bbae7; /* Primary color */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}


@media (max-width: 768px) {
    .contact-container img {
        padding: 0 20px;
    }

    form {
        padding: 20px;
    }
}
