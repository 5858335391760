/* Container styling */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 70px;
  flex-wrap: wrap;
  font-family: 'Montserrat';
}

/* Image container styling */
.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.image-container img {
  max-width: 70%;
  border-radius: 10px;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
}

/* Content container styling */
.content-container {
  flex: 1;
  padding: 20px;
  max-width: 600px;
}

.content-container h1 {
  font-size: 2.5em;
  margin: 0 0 10px;
  font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;;
  color: #6AADE5; /* Tealish Blue */
}

.content-container h3 {
  font-size: 1.3em;
  color: rgba(0, 0, 0, 0.72);
  margin: 0 0 20px;
  font-style: italic;
  padding-bottom: 5px;
  border-bottom: 2px solid black;
  font-family: 'Barlow';
}

.content-container p {
  font-size: 1em;
  line-height: 1.6;
  font-family: 'Montserrat';
  margin-bottom: 20px;
  text-align: left;
}

/* Button container styling */
.button-container {
  text-align: center;
  margin-top: 20px;
}

.button-container .book-now {
  background: linear-gradient(135deg, #D0E1F9, #A9A9A9); /* Light Blue to Gray */
  color: #273B5A; /* Navy Blue */
  border: none;
  padding: 15px 30px;
  margin: 0 10px;
  cursor: pointer;
  font-family: 'Montserrat';
  font-size: 20px;
  border-radius: 20px;
  transition: background 0.3s, box-shadow 0.3s, transform 0.3s;
}

.button-container .book-now:hover {
background-color: rgb(124, 173, 213);
}
/* Additional information styling */
.additional-info {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  text-align: left;
}

.additional-info h1 {
  font-size: 1.3em;
  color: #1E3A5F;
  margin-top: 20px;
  text-transform: uppercase;
 
  border-bottom: 2px solid #1E3A5F;

}

.additional-info p, .additional-info ul {
  font-size: 18px;
  color: #333;
}

.additional-info p {
  margin-bottom: 10px;
  margin-top: 15px;
}

.additional-info ul {
  padding-left: 40px;
  margin-left: 0; /* Ensure no additional left margin */
  list-style: none; /* Remove default list style */
  color: #6AADE5; /* Tealish Blue */
  margin-top: 10px;
  font-weight: bold;
}

.additional-info ul li {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 20px; /* Space for custom bullet */
}

.additional-info ul li::before {
  content: '•';
  font-size: 1.6em;
  color: #6AADE5; /* Tealish Blue */
  margin-right: 10px;
  font-weight: bolder;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .additional-info {
    padding: 15px;
  }

  .additional-info h2 {
    font-size: 1.5em;
    text-align: left;
  }

  .additional-info p, .additional-info ul {
    font-size: 1em;
    align-items: flex-start;
    text-align: left;
  }
}

/* Main container styling */
.main-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

/* Ingredient wrapper styling */
.ingredient-wrapper {
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
}

/* Individual ingredient container styling */
.ingredient-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  background-color: #FAF9F6;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ingredient-container img {
  max-width: 50px; 
  height: auto;
  margin-bottom: 0px;
}

.ingredient-container h1 {
  font-size: 22px;
  color: #204370;
  margin: 0px 0;
  text-align: center;
}

.ingredient-container h3 {
  font-size: 17px;

  color: #265a9f;
  margin: 10px 0;
  text-align: center;
  text-transform: lowercase;
  font-weight: 100;
}

/* Banner styling */
.banner {
  width: 100%;
  font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
  background-color: #b4def3; /* Adjust this color to match your theme */
  color: #1e5379; /* Text color */
  font-size: 1.5em;
  text-align: center;
  padding: 20px 0;
  margin: 20px 0; /* Adjust this margin as needed */
  font-weight: bold;
  border-radius: 5px; /* Optional: to add rounded corners */
  letter-spacing: 1ch;
}


