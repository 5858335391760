body {
  margin: 3px;
  font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
}

.background {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  overflow: hidden;
  background-color: rgb(244, 249, 253);
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
}

.background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: -1;
}

.background h2 {
  font-family: 'Montserrat';
  font-size: 50px;
  text-transform: uppercase;
  color: var(--secondary-color);
  font-weight: 400;


}

.step-row {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  margin: 20px 0;
  padding: 20px;
  color: #000;
  opacity: 0;
  transform: translateY(20px);
  animation: slideIn 1s forwards;
}

.step-row.left {
  justify-content: flex-start;
  text-align: left;
}

.step-row.right {
  justify-content: flex-end;
  text-align: right;
}

.step-content {
  max-width: 600px;
}

.step-content h3 {
  font-size: 1.3em;
  margin: 0 0 10px 0;
  text-transform: uppercase;
  color: #6aade5;
 
  letter-spacing: 1mm;
  font-weight: 700;
  animation: fadeInUp 1s ease-in-out;
}

.step-content p {
  font-size: 1em;
  margin: 0;
  animation: fadeInUp 1s ease-in-out;
}

.step-number h1 {
  font-size: 3em;
  font-weight: bold;
  margin: 0 20px;
  font-family: 'Allura';
  animation: fadeIn 1s ease-in-out;
}

.step-row.left .step-number {
  order: -1;
}

@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.step-row:nth-child(2) {
  animation-delay: 0.2s;
}

.step-row:nth-child(3) {
  animation-delay: 0.4s;
}

.step-row:nth-child(4) {
  animation-delay: 0.6s;
}

.step-row:nth-child(5) {
  animation-delay: 0.8s;
}
