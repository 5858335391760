/* IV Therapy container styling */
.iv-therapy {
    margin-bottom: 50px;
    margin-top: 50px;
}

/* Main container for IV therapy content */
.iv-therapy-container {
    display: flex;
    justify-content: space-between;
    padding-left: 50px;
    border-radius: 10px;
    max-width: 1100px;
    margin: auto;
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
    color: #272401;
}

/* Header styling */
.iv-therapy-header {
    margin-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
    color: #6AADE5; /* Tealish Blue */
    background-color: rgba(255, 255, 255, 0.3);
    font-weight: bold;
    border-bottom: 1px solid  #74BBDF; /* Tealish Blue */
    border-top: 1px solid #74BBDF; /* Tealish Blue */;
}

/* Image and text container */
.iv-therapy-image-text {
    max-width: 50%;
    margin-right: 20px;
}

/* Styling for h3 inside image-text container */
.iv-therapy-image-text h3 {
    color: #6AADE5; /* Tealish Blue */
    margin-bottom: 20px;
    font-size: 1.5em;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

/* Styling for p inside image-text container */
.iv-therapy-image-text p {
    color: #272401;
    margin-bottom: 20px;
    line-height: 1.6;
    font-size: 1.1em;
    font-weight: 400;
}

/* Image styling */
.iv-therapy-image {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Section for services */
.iv-therapy-section {
    max-width: 45%;
    flex: 1;
}

/* Service list container */
.service-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

/* Service button styling */
.service-button {
    background-color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    color: #273B5A;
    border: 1px solid #74BBDF; /* Tealish Blue */;
    padding: 15px 20px;
    margin: 10px 0;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    text-align: left;
    position: relative;
    font-size: 1em;
    transition: background-color 0.3s, transform 0.3s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Service button hover effect */
.service-button:hover {
    background-color: #5CB8E6;
    transform: translateY(-2px);
    color: #ffffff;
}

/* Icon styling */
.icon {
    font-size: 1.5em;
    transition: transform 0.3s;
    color: #74BBDF; /* Tealish Blue */
}

/* Icon rotation for open state */
.icon.open {
    transform: rotate(180deg);
}

/* Service content container */
.service-content {
    background-color: #f6f6f5;
    color: #272401;
    padding: 0 10px;
    margin: 10px 0;
    border-radius: 5px;
    width: 100%;
    max-width: 600px;
    animation: fadeIn 0.5s ease-in-out;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 1.1em;
    line-height: 1.6;
    text-align: left;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
}

/* Open state for service content */
.service-content.open {
    padding: 10px;
    max-height: 500px;
}

/* List styling inside service content */
.service-content ul {
    list-style-position: inside;
    padding: 0;
    margin-left: 20px;
}

/* List item styling inside service content */
.service-content li {
    margin-bottom: 10px;
    font-size: 18px;
    color: #333;
    margin: 0 5 20px 0;
}

/* Fade-in animation */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
