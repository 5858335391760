/* Main container for reviews */
.reviews-container {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 60px;
  margin-top: 30px;
  position: relative;
  padding: 20px;
}

/* Header styling for the reviews section */
.reviews-container h2 {
  font-size: 50px;
  margin-bottom: 30px;
  color: #9bbae7; /* Primary color */
  font-weight: bold;
}

/* Individual review styling */
.review {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Content within each review */
.review-content {
  padding: 40px;
  background: #ffffff; /* Light Cream */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  width: 100%;
  height: 200px;
  text-align: left;
  border: 1px solid #9bbae7; /* Primary color border */
}

/* Reviewer's name styling */
.review-content h3 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #557373; /* Dark Text */
}

/* Review text styling */
.review-content p {
  font-size: 18px;
  margin-bottom: 10px;
  color: #0d0d0d; /* Text Color */
}

/* Star rating styling */
.rating {
  color: #ffc107; /* Star color */
  margin-bottom: 10px;
}

/* Previous and next button styling */
button.prev, button.next {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #9bbae7; /* Primary color */
}

/* Positioning for previous button */
button.prev {
  left: -40px;
}

/* Positioning for next button */
button.next {
  right: -40px;
}

/* Leave review button styling */
button.leave-review {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #9bbae7; /* Primary color */
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Hover effect for leave review button */
button.leave-review:hover {
  background-color: #557373; /* Dark hover color */
}
