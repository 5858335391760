

/* Slide hover effect */
.treatment-slide:hover {
    transform: scale(1.1);
    z-index: 1; /* Ensure it appears above other slides */
}

/* Slide image styling */
.treatment-slide img {
    width: 100%;
    height: auto;
    border-radius: 30px;
    margin-bottom: 0px;
}

/* Slide title styling */
.treatment-slide h3 {
    font-size: 1.1em;
    color: #1E3A5F;
    margin-top: 10px;
    margin-bottom: 0px;
}

/* Slide paragraph styling */
.treatment-slide p {
    font-size: 18px;
    color: #273B5A; /* Navy Blue */
    font-family: 'Montserrat', sans-serif;
    margin: 5px;
}



