@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

@font-face {
  font-family: 'Allura';
  src: url('../fonts/Allura-Regular.ttf') format('truetype'); /* Adjust the path and format as needed */
  font-weight: normal;
  font-style: normal;
}

.main-content {
  text-align: center;
  position: relative;
  font-family: 'Montserrat';
  
}

.homepage-section {
  width: 100%; /* Full width */
  margin: 0 auto; /* Center the container */
  box-sizing: border-box;
}

.homepage-logo {
  width: 350px;
  margin: 0px;
}

.homepage-logo img {
  width: 100%;
  height: auto;

}

.homepage-text {
  max-width: 80%;
  z-index: 10;
  text-align: center;
  color: #424b50; 
  font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 600;
  line-height: 2em;
  margin: 0px;
  
}

.homepage-text h3 {
  font-family: 'Montserrat';
  font-size: 40px;
  text-transform: uppercase;
  color: #45494c;
  font-weight: 500;
  margin: 0;
}

.homepage-text h4 {
  font-size: 50px;
  color: rgb(144, 193, 233);
  text-transform: none;
  font-family: 'Allura';
  font-weight: 100;
  margin: 10px;
  align-items: flex-start;
}

.homepage-buttons {
  margin-top: 20px;
}

.homepage-buttons button {
  background-color: #74BBDF; 
  color: white;
  border: none;
  padding: 15px 40px;
  margin: 0 20px;
  font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;

  border-radius: 5px;
  transition: background 0.3s, box-shadow 0.3s, transform 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


.homepage-image-container {
  display: none; /* Hides the background image container */

}


.droplet {
  position: relative;
}

.pricing img {
  height: 100px;
  width: auto;
}

.additional-text {
  padding: 40px;
  padding-left: 60px;
  padding-right: 40px;
  text-align: left;
  color: #273B5A; /* Navy Blue */
  font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
}

.additional-text h1 {
  color: #74BBDF; /* Tealish Blue */
  font-size: 24px;
  margin-top: 20px;
  border-bottom: 1px solid #273B5A;
}

.additional-text p {
  font-size: 18px;
  line-height: 1.6;
  margin-top: 10px;
  margin: 30px;
}

.homepage {
  position: relative;
  text-align: center;
  color: white;
}

.waves {
  position: relative;

}

.waves img {
  width: 100%;

  height: 80vh;
  display: block;
}

.waves h1 {
  font-family: 'Allura';
  position: absolute;
  
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 90px;
  color: #74BBDF; /* Tealish Blue */
  font-weight: 100;
}


.view-services {
  color: #74BBDF; /* Tealish Blue */
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.view-services:hover {
  background-color: #557373; /* Dark hover color */
}

.hidden-text {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.visible-text {
  opacity: 1;
  transform: translateY(0);
}
