
@font-face {
  font-family: 'Allura';
  src: url('../fonts/Allura-Regular.ttf') format('truetype'); 
  font-weight: normal;
  font-style: normal;
}

@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(0px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}


@keyframes slideInLeft {
  from {
      opacity: 0;
      transform: translateX(-100%);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

.vitamin-services-text {
  display: flex;
  align-items: center; /* Align items vertically centered */
  justify-content: center; /* Center items horizontally */
  text-align: left; /* Ensure text is left-aligned */
  animation: slideInLeft 1s ease-in-out;
}

.vitamin-services-text img {
  max-width: 250px;
  height: auto;
  margin-right: 20px; /* Add some space between the image and the text */
}

.vitamin-IV-services {
    padding: 0px;
    background-color: #ffffff; /* Light Cream */
    border-radius: 10px;
    text-align: center;
    width: 100%; /* Ensure the section spans the full width */
    box-sizing: border-box; /* Include padding in the width calculation */
    margin: 0 auto; /* Center the section */

    animation: fadeIn 1s ease-in-out;
}

.vitamin-services-text {
  padding-top: 30px;
}
  
  .vitamin-text-content h1 {
    font-family: 'Montserrat';
    font-size: 50px;
    text-transform: uppercase;
    color: #45494c;
    font-weight: 400;
    margin: 0;
  }
  
  .vitamin-IV-services h3 {
    font-size: 70px;
    color: rgb(144, 193, 233);
    text-transform: none;
    font-family: 'Allura';
    font-weight: 100;
    margin: 10px;
    align-items: flex-start;
  }



  .vitamin-services h1 {
    font-size: 100px;
    color: rgb(144, 193, 233);
    text-transform: none;
    font-family: 'Allura';
    font-weight: 100;
    margin: 10px;

  }



  .vitamin-IV-services p {
    font-family: 'Montserrat';
    font-size: 20px;

    color: #45494c;
    font-weight: 200;
    margin: 10;
    padding-left: 80px;
    padding-right: 80px;
  }
  
  .treatment-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-left: 30px;
    padding-right: 30px;

  }
  
  .treatment-card {

    padding: 0px;
    margin: 20px;
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.3s ease;
  }
  
  .treatment-card:hover {
    transform: scale(1.05);
  }
  
  .treatment-card img {
    width: 120px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
    align-self: center;
  }
  
  .treatment-card h3 {
    font-size: 17px;
    color: #1E3A5F;
    font-weight: 700;
    
    text-align: center;
    margin: 5px;
    padding-left: 0;
  }
  
  .treatment-card h1 {
    font-size: 20px;
    margin: 10px 0;
    color: #0d0d0d;
    font-weight: bold;
    text-decoration: underline;
    text-decoration-color: #6aade5;
    text-underline-offset: 10px; /* Adjust this value to move the line lower */
  }
  
  .treatment-card h1:hover {
    color: #6aade5;
  }
  

  .treatment-card p {
    font-size: 17px;
    color: #1E3A5F;
    font-weight: 500;
    width: 250;
    text-align: center;
    margin: 5px;
    padding-bottom: 0;
  }
  

/* Learn more button styling */
.learn-more-button {
  display: inline-block;
  padding: 12px 20px;
  color: #ffffff;

  background: #6aade5;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-size: 15px;
  font-weight: bold;
  margin: 5px;
  transition: background 0.3s, box-shadow 0.3s, transform 0.3s;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-top: auto; /* Push button to the bottom */
}




