
.IV-services {
    margin-top: 50px;
    padding: 0px;
}

.peptide-info {
    margin-top: 50px;
    margin-bottom: 30px;
}
.injections-FAQ {
    padding: 30px;
    margin-top: 50px;
}


.Semaglutide {
    margin-top: 50px;
    margin-bottom: 50px;
}

.Semaglutide h1 {
    margin-top: 20px;
    margin-bottom: 20px;
}

  .droplet img {
    height: 100px;
    width: auto;
  }