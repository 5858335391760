@import '@fortawesome/fontawesome-free/css/all.css';

@font-face {
  font-family: 'Barlow';
  src: url('./fonts/Barlow/Barlow-Regular.ttf') format('truetype'); /* Adjust the path and format as needed */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/static/Montserrat-Light.ttf') format('truetype'); /* Adjust the path and format as needed */
  font-weight: normal;
  font-style: normal;
}

.main-content {
  padding-top: 60px;
}
.footer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #FFFFFF; /* Light Blue */
  z-index: 1000; /* Ensure it’s above other elements */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */
}


body {
  margin: 0;
  font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
  background-color: #FFFFFF; /* Light Blue */
  color: #273B5A; /* Navy Blue */
}

.footer {
  background-color: #FFFFFF; /* Light Blue */
  color: #273B5A; /* Navy Blue */
  width: 100%;
  font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
  padding: 0;

}

.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 140px; /* Adjust padding as needed */
}

.logo img {
  height: 60px;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 40px;
  margin: 0;
  padding: 0;
  font-size: 15px;
  text-transform: uppercase;
  font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
}

.nav-links li {
  position: relative;
}

.nav-links li a {
  color: #5b5b5b; /* Very Dark Gray */
  text-decoration: none;
  transition: color 0.3s;
}

.nav-links li a:hover {

  color: rgb(60, 142, 209);

}

.nav-links .dropdown-container {
  display: flex;
  align-items: center;
}

.nav-links .dropdown-container i {
  margin-left: 5px;
  transition: transform 0.3s;
}

.nav-links .dropdown-container:hover i {
  transform: rotate(180deg);
  color: rgb(60, 142, 209);
}

.nav-links .dropdown {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
  list-style: none;
  padding: 10px;
  border-radius: 5px;
  font-size: 15px;
  top: 100%;
  left: 0;
}

.nav-links li:hover .dropdown {
  display: block;
  color: rgb(60, 142, 209);
}

.dropdown li {
  margin: 0;
  padding: 10px 20px;
}

.dropdown li a {
  color: #0d0d0d; /* Very Dark Gray */
  text-decoration: none;
  transition: color 0.3s;
}

.dropdown li a:hover {
  color: rgb(60, 142, 209);
}

.social {
  display: flex;
  gap: 15px;
}

.social-icon {
  background-color: #6aade5; /* Dark Blue */
  color: #FFFFFF; /* White */
  padding: 10px;
  border-radius: 50%;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.3s;
}




.bottom {
  background-color: #FFFFFF; /* Light Blue */
  color: #273B5A; /* Navy Blue */
  width: 100%;
  padding: 20px 0;
}

.footer-sections {
  display: flex;
  justify-content: space-around;
  padding: 20px 140px;
}

.footer-column {
  flex: 1;
}

.footer-column h3 {
  font-size: 18px;
  color: #273B5A;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin: 10px 0;
}

.footer-column ul li a {
  color: #5b5b5b; /* Very Dark Gray */
  text-decoration: none;
  font-size: 15px;
}

.footer-column ul li a:hover {
  color: rgb(60, 142, 209);
}

.footer-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-button {



  padding: 10px 20px;
  margin: 10px 0;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
  transition: background-color 0.3s;

  background-color: #74BBDF; 
  color: white;
  border: none;


  font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
  cursor: pointer;
  font-weight: bold;


  border-radius: 5px;
  transition: background 0.3s, box-shadow 0.3s, transform 0.3s;

  
}
.footer-button:hover {
  background-color: rgb(124, 173, 213);
}


.footer-bottom {
  text-align: center;
  margin-top: 20px;
}

.footer-bottom p {
  font-size: 15px;
}

.dropdown-container-right {
  position: relative;
}

.dropdown-right {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
  list-style: none;
  padding: 10px;
  border-radius: 5px;
  font-size: 15px;
  top: 0;
  left: 100%;
}

.dropdown-container-right:hover .dropdown-right {
  display: block;
  
}

