.about-us-container {
    font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
    color: #0d0d0d;
    margin: 0px;

    animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(0px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.droplet {
    position: relative;
  }
  
  .droplet img {
    height: 100px;
    width: auto;
  }

.hero-content {
    display: flex;
    margin-top: 50px;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
    animation: slideInLeft 1s ease-in-out;
}

@keyframes slideInLeft {
    from {
        opacity: 0;
        transform: translateX(-100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.hero-content img {
    width: 150px;
    height: auto;
    margin-right: 20px;
    transition: transform 0.3s ease-in-out;
}

.hero-content img:hover {
    transform: scale(1.1);
}

.hero-content h1 {
    font-family: 'Montserrat';
    font-size: 50px;
    text-transform: uppercase;
    color: #45494c;
    font-weight: 400;
    margin: 0;
}

.our-mission, .our-founder, .client-reviews {
    margin: 20px 0;
    animation: fadeIn 1s ease-in-out;
}

h2 {
    font-size: 56px;
    margin-bottom: 10px;
    color: #9bbae7; /* Dark Green */
    font-family: 'Allura';
    position: relative;
    display: inline-block;
    padding-bottom: 10px; /* Adds some space between the text and the underline */
}

h2::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px; /* Adjust the height of the underline */
    background-color: #273B5A; /* Dark Green */
    transform: scaleX(1.7); /* Adjust the scale to make the underline extend beyond the text */
}

.our-mission p {
    font-size: 20px;
    line-height: 1.6;
    font-weight: 500;
    margin-bottom: 15px;
    padding-left: 50px;
    padding-right: 50px;
    animation: fadeIn 2s ease-in-out;
}

p {
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 15px;
    padding-left: 50px;
    padding-right: 50px;
    animation: fadeIn 1s ease-in-out;
}

.our-founder .founder {
    display: flex;
    align-items: center;
    background-color: aliceblue;
    gap: 20px;
    margin-bottom: 50px;
    margin-top: 30px;
    justify-content: center;
    animation: slideInRight 1s ease-in-out;
}



.our-founder .founder-image {
    border-radius: 40%;
    width: 300px;
    height: 300px;
    object-fit: cover;
    border: 3px solid #557373; /* Dark Green */
    transition: transform 0.3s ease-in-out;
}

.our-founder .founder-image:hover {
    transform: scale(1.1);
}

.our-founder .founder-info {
    max-width: 600px;
}

.our-founder .founder-info h3 {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: bold;
}

.our-founder p {
    font-size: 20px;
    line-height: 1.6;
    font-weight: 500;
    margin-bottom: 15px;
    padding-left: 50px;
    padding-right: 50px;
    animation: fadeIn 2s ease-in-out;
}

.hashtag {
    display: flex;
    padding-left: 100px;
    padding-right: 100px;
    animation: fadeIn 1s ease-in-out;
}

.hashtag-header h1 {
    font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
    color: #0d0d0d;
    font-size: 30px;
}

.hashtag-header h3 {
    font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
    color: #0d0d0d;
    font-weight: 300;
    font-size: 18px;
}

.hashtag-info {
    display: flex;
    animation: fadeIn 1s ease-in-out;
}

.hashtag-info img {
    max-height: 400px;
    width: auto;
    padding-right: 20px;
    align-self: center;
    transition: transform 0.3s ease-in-out;
}

.hashtag-info img:hover {
    transform: scale(1.1);
}

.hashtag-text {
    flex-direction: row;
    align-items: flex-start;
    padding: 30px;
    margin: 0px;
}

.nurse-image img {
    max-width: 250px;
    height: auto;
    animation: fadeIn 1s ease-in-out;
}
